// const manifest = {
//   VM: true,
//   RESSELER: 145,
//   CODE_AUTO: true,
//   CODE_AUTO_CODE: '4DF62C6J' //'4DF59911',
// };

const manifest = {
  VM: true,
  RESSELER: 149,
  CODE_AUTO: true,
  CODE_AUTO_CODE: '008E09' //'4DF59911',
};

export default manifest;
